export const shows = [
  // {
  //   date: '16 March 2024',
  //   project: 'ПСИХЕЯ / Разное',
  //   place: 'Урбан',
  //   city: 'Москва',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/psy_msk2024',
  //   ticketLink: 'https://psymsc1603.ticketscloud.org'
  // },
  // {
  //   date: '04 April 2024',
  //   project: 'ПСИХЕЯ / Разное',
  //   place: 'MOD',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/psychea2303',
  //   ticketLink: 'https://vk.com/app6112319'
  // },
  // {
  //   date: '21 April 2024',
  //   project: 'FEODOQ / RAW POP FEST',
  //   place: 'PODDON',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/rawpopgig',
  //   ticketLink: 'https://fuzz-i-druzhba.timepad.ru/event/2821315/'
  // },
  // {
  //   date: '04 May 2024',
  //   project: 'ФЕО / АКУСТИКА',
  //   place: 'МХиС фест',
  //   city: 'Орёл',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/mhisfest2',
  //   ticketLink: ''
  // },
  // {
  //   date: '12 May 2024',
  //   project: 'ПСИХЕЯ',
  //   place: 'ФАНТАСМАГОРИЯ фест',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/phantasmagoria_1205',
  //   ticketLink: 'https://phantasmagoria.ticketscloud.org'
  // },
  // {
  //   date: '18 May 2024',
  //   project: 'FEODOQ',
  //   place: 'Ласточка',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: '',
  //   ticketLink: 'https://feodoq.ticketscloud.org'
  // },
  // {
  //   date: '30 May 2024',
  //   project: 'ШТЫКНОЖ',
  //   place: 'Ласточка',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/shtiknoj3005',
  //   ticketLink: 'https://vk.com/app7930230'
  // },
  // {
  //   date: '01 June 2024',
  //   project: 'ФЕО Акустика',
  //   place: 'Дизайн Завод',
  //   city: 'Москва',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/feoroof2024',
  //   ticketLink: 'https://feo.ticketscloud.org'
  // },
  // {
  //   date: '21 June 2024',
  //   project: 'ПСИХЕЯ',
  //   place: 'Pravda',
  //   city: 'Москва',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/psy_summer24_msk',
  //   ticketLink: 'https://psysummer24.ticketscloud.org'
  // },
  // {
  //   date: '28 June 2024',
  //   project: 'ПСИХЕЯ',
  //   place: 'СМЫСЛ фест',
  //   city: 'Нижний Новгород',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/smyslfest',
  //   ticketLink: 'https://nnovgorod.qtickets.events/100655-smysl-21-23-iyunya'
  // },
  // {
  //   date: '20 July 2024',
  //   project: 'ПСИХЕЯ',
  //   place: 'ПЛЯЖ фест',
  //   city: 'Челябинск',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/beachfest74',
  //   ticketLink: 'https://beach-fest.ru'
  // },
  // {
  //   date: '21 July 2024',
  //   project: 'ПСИХЕЯ',
  //   place: 'Зеленая Сцена',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/xx_psychea',
  //   ticketLink: 'https://0707psychea.ticketscloud.org/'
  // },
  // {
  //   date: '31 July 2024',
  //   project: 'ПСИХЕЯ',
  //   place: 'Акустика на Корабле',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: '',
  //   ticketLink: 'https://psiheya-korabl.ticketscloud.org'
  // },
  // {
  //   date: '01 August 2024',
  //   project: 'ПСИХЕЯ',
  //   place: 'Электричество на Корабле',
  //   city: 'Москва',
  //   country: 'Россия',
  //   eventLink: '',
  //   ticketLink: 'https://psiheyaship.ticketscloud.org'
  // },
  // {
  //   date: '23 August 2024',
  //   project: 'ШТЫКНОЖ',
  //   place: 'ПОСТ-ФЕСТ 8',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: '',
  //   ticketLink: 'https://postfest-8.ticketscloud.org/'
  // },
  // {
  //   date: '24 August 2024',
  //   project: 'ПСИХЕЯ',
  //   place: 'ПОСТ-ФЕСТ 8',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: '',
  //   ticketLink: 'https://postfest-8.ticketscloud.org/'
  // },
  // {
  //   date: '27 September 2024',
  //   project: 'ПСИХЕЯ',
  //   place: 'Аврора',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: '',
  //   ticketLink: 'https://psy-xxix.ticketscloud.org'
  // },
  // {
  //   date: '4 October 2024',
  //   project: 'ПСИХЕЯ',
  //   place: 'Base',
  //   city: 'Москва',
  //   country: 'Россия',
  //   eventLink: '',
  //   ticketLink: 'https://psy29.ticketscloud.org'
  // },

  // {
  //   date: '07 December 2023',
  //   project: 'ФЕО / АКУСТИКА',
  //   place: 'Ласточка',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/feospb2023',
  //   ticketLink: 'https://feospb2023.ticketscloud.org/'
  // },
  // {
  //   date: '09 December 2023',
  //   project: 'ФЕО / АКУСТИКА',
  //   place: 'Джао Да',
  //   city: 'Москва',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/feomsk2023',
  //   ticketLink: 'https://feo.ticketscloud.org'
  // },
  // {
  //   date: '17 December 2023',
  //   project: 'ШТЫКНОЖ',
  //   place: 'Ласточка',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/shtiknoj1712',
  //   ticketLink: 'https://vk.com/app7930230'
  // },
  // {
  //   date: '24 December 2023',
  //   project: 'ШТЫКНОЖ',
  //   place: 'Бумажная фабрика',
  //   city: 'Москва',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/shtiknoj2412',
  //   ticketLink: 'https://vk.com/app7930247'
  // },
  // {
  //   date: '04 January 2024',
  //   project: 'ПСИХЕЯ / КИБЕРАКУСТИКА',
  //   place: 'Ласточка',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/event223558399',
  //   ticketLink: 'https://vk.com/app6112319'
  // },
  // {
  //   date: '06 January 2024',
  //   project: 'ПСИХЕЯ / КИБЕРАКУСТИКА',
  //   place: 'Pravda',
  //   city: 'Москва',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/psychea_msk2024',
  //   ticketLink: 'https://psy2024.ticketscloud.org'
  // },
  // {
  //   date: '17 November 2024',
  //   project: 'ПСИХЕЯ / Выгорание',
  //   place: 'Arma',
  //   city: 'Москва',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/otorvisebelizo151711',
  //   ticketLink: 'https://vk.com/app5575136_-227362685'
  // },
  // {
  //   date: '20 November 2024',
  //   project: 'Дмитрий Фео Порубов / Презентация Книги',
  //   place: 'Фонтантка - 69',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/event228132565',
  //   ticketLink: 'https://gad-spb.ticketscloud.org'
  // },
  // {
  //   date: '24 November 2024',
  //   project: 'ФЕО / Акустика',
  //   place: 'Бастион',
  //   city: 'Калининград',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/event227657871',
  //   ticketLink: 'https://kaliningrad.qtickets.events/132817-feo-psikheya-24112024'
  // },
  // {
  //   date: '28 November 2024',
  //   project: 'ПСИХЕЯ / Киберакустика',
  //   place: 'Ласточка',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: '',
  //   ticketLink: 'https://psykiberspb.ticketscloud.org/'
  // },
  // {
  //   date: '07 December 2024',
  //   project: 'ПСИХЕЯ / Киберакустика',
  //   place: 'Техника Безопасности',
  //   city: 'Москва',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/event228132565',
  //   ticketLink: 'https://psykibermsc.ticketscloud.org/'
  // },
  // {
  //   date: '06 December 2024',
  //   project: 'ШТЫКНОЖ',
  //   place: 'Ласточка',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/10bbt',
  //   ticketLink: 'https://spb.qtickets.events/137363-10-let-leyblu-bastard-boogie-tunes'
  // },
  // {
  //   date: '15 December 2024',
  //   project: 'Дмитрий Фео Порубов / Презентация Книги',
  //   place: 'Pub No Pub',
  //   city: 'Москва',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/event228132996',
  //   ticketLink: 'https://gad-msk.ticketscloud.org'
  // },
  // {
  //   date: '19 December 2024',
  //   project: 'ВОЛКОСОБ',
  //   place: 'Ласточка',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/event228495685',
  //   ticketLink: 'https://volkosob.ticketscloud.org/'
  // },
  // {
  //   date: '05 January 2025',
  //   project: 'ПСИХЕЯ / Электричество',
  //   place: 'MOD',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/event228512063',
  //   ticketLink: 'https://psy-spb2025.ticketscloud.org'
  // },
  // {
  //   date: '08 January 2025',
  //   project: 'ПСИХЕЯ / Электричество',
  //   place: 'Урбан',
  //   city: 'Москва',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/event228510395',
  //   ticketLink: 'https://psymsc25.ticketscloud.org'
  // },
  // {
  //   date: '16 January 2025',
  //   project: 'FEODOQ',
  //   place: 'Ласточка',
  //   city: 'Санкт-Петербург',
  //   country: 'Россия',
  //   eventLink: 'https://vk.com/event228880752',
  //   ticketLink: 'https://vk.com/app7930192'
  // },
  {
    date: '24 January 2025',
    project: 'ФЕО / Акустика',
    place: 'Ласточка',
    city: 'Санкт-Петербург',
    country: 'Россия',
    eventLink: 'https://vk.com/club228742290',
    ticketLink: 'https://feo2025.ticketscloud.org'
  },
  {
    date: '25 January 2025',
    project: 'ФЕО / Акустика',
    place: 'Stereo People',
    city: 'Москва',
    country: 'Россия',
    eventLink: 'https://vk.com/feo2025',
    ticketLink: 'https://feo.ticketscloud.org'
  },
  {
    date: '07 March 2025',
    project: 'ПСИХЕЯ / ПТНРС',
    place: 'Factory 3',
    city: 'Санкт-Петербург',
    country: 'Россия',
    eventLink: 'https://vk.com/club229072861',
    ticketLink: 'https://psychea.ticketscloud.org'
  },
  {
    date: '16 March 2025',
    project: 'ПСИХЕЯ / ПТНРС',
    place: 'Pravda',
    city: 'Москва',
    country: 'Россия',
    eventLink: 'https://vk.com/club229065822',
    ticketLink: 'https://psymosc2025.ticketscloud.org'
  },
]
