import React from 'react';

function Footer() {
  return (
    <footer className='footer'>
      <div>
        ПСИХЕЯ
      </div>
      <div>
        НАВСЕГДА
      </div>
      <div>
        2025
      </div>
    </footer>
  )
}

export default Footer;
